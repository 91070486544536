.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}
 
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(9, 236, 96, 0.199);
  border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(191, 199, 247);
  background-color: rgb(167, 180, 253);
}

.table-view-x-scroll{
  overflow-y: scroll;
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

.custom-date-picker .rs-input-group.rs-input-group-inside .rs-input {
  font-size: 0.75rem !important; 
}

.custom-date-picker .rs-input-group.rs-input-group-inside .rs-input-group-addon {
  font-size: 0.75rem !important; 
}

.table-header {
  background-color: #F9F9F9;
  font-weight: 400; 
}

.table-body {
  font-weight: '600';
  color: '#4A4646'
}